import React, { useState, useEffect } from "react"
import { Accordion } from "react-bootstrap"

const LoginWithGoogle = () => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const loadGoogleAPI = () => {
    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.onload = () => {
      import("gapi-script").then(({ gapi }) => {
        gapi.load("client:auth2", () => {
          gapi.auth2.init({
            client_id: process.env.GATSBY_GOOGLE_CLIENT_ID,
          })

          const authInstance = gapi.auth2.getAuthInstance()
          authInstance.isSignedIn.listen(updateSignInStatus)
          updateSignInStatus(authInstance.isSignedIn.get())
        })
      })
    }
    document.body.appendChild(script)
  }

  const updateSignInStatus = signedIn => {
    setIsSignedIn(signedIn)
  }

  const handleGoogleSignIn = () => {
    import("gapi-script").then(({ gapi }) => {
      const auth2 = gapi.auth2.getAuthInstance()
      auth2
        .signIn({
          scope: "https://www.googleapis.com/auth/calendar",
        })
        .then(() => {
          setIsSignedIn(true)
        })
    })
  }

  const handleGoogleSignOut = () => {
    import("gapi-script").then(({ gapi }) => {
      const auth2 = gapi.auth2.getAuthInstance()
      auth2.signOut().then(() => {
        setIsSignedIn(false)
      })
    })
  }

  useEffect(() => {
    loadGoogleAPI()
  }, [])

  return (
    <Accordion.Item>
      <div className="row g-0 block-content align-items-center border-bottom flex-sm-row flex-column">
        <div className="col-md-3">
          <h5 className="block-title">Login with</h5>
        </div>
        <div className="col-md-9 text-sm-end text-center pr-15">
          <div className="loginWithSocialToggle mx-1" data-auth="Google">
            {!isSignedIn && (
              <button className="btn btnGoogleOutline" onClick={handleGoogleSignIn}>
                <i className="bi bi-plugin"></i> Connect to Google
              </button>
            )}

            {isSignedIn && (
              <button
                className="btn btnGoogleOutline btnDisconnenctFromGoogle"
                onClick={handleGoogleSignOut}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <i className={isHovered ? `bi bi-x-lg` : `bi bi-check2`} />{" "}
                {isHovered ? "Disconnect from Google" : "Connected to Google"} <i className="bi bi-google" />
              </button>
            )}
          </div>
        </div>
      </div>
    </Accordion.Item>
  )
}

export default LoginWithGoogle
